export default {
  menu_price: 'menu_price',
  menu_tokenBuy: 'menu_tokenBuy',
  menu_myAccount: 'menu_myAccount',
  menu_transfer: 'menu_transfer',
  menu_exchange: 'menu_exchange',
  menu_tokenSale: 'menu_tokenSale',
  menu_menu: 'menu_menu',
  menu_cookieAlert: 'menu_cookieAlert',
  integration_bank: 'integration_bank',
  integration_join: 'integration_join',
  integration_digital: 'integration_digital',
  integration_connects: 'integration_connects',
  integration_beecome: 'integration_beecome',
  integration_broker: 'integration_broker',
  integration_bokerage: 'integration_bokerage',
  integration_brokerApi: 'integration_brokerApi',
  integration_online: 'integration_online',
  integration_integra: 'integration_integra',
  integration_will: 'integration_will',
  integration_retail: 'integration_retail',
  integration_integration: 'integration_integration',
  integration_willOpen: 'integration_willOpen',
  integration_api: 'integration_api',
  integration_if: 'integration_if',
  integration_apiBelow: 'integration_apiBelow',
  integration_showModal: 'integration_showModal',
  integration_name: 'integration_name',
  integration_web: 'integration_web',
  integration_country: 'integration_country',
  integration_firsName: 'integration_firsName',
  integration_secondName: 'integration_secondName',
  integration_position: 'integration_position',
  integration_phone: 'integration_phone',
  integration_email: 'integration_email',
  integration_imagebank: 'integration_imagebank',
  integration_imageBrocker: 'integration_imageBrocker',
  integration_imageOnLineStore: 'integration_imageOnLineStore',
  integration_imageRetail: 'integration_imageRetail',
  global_join: 'global_join',
  global_watchVideo: 'global_watchVideo',
  global_joinUs: 'global_joinUs',
  global_nameProject: 'global_nameProject',
  global_submit: 'global_submit',
  header_paymentServices: 'header_paymentServices',
  header_description: 'header_description',
  header_comparison: 'header_comparison',
  header_efficiency: 'header_efficiency',
  header_benefit: 'header_benefit',
  header_indicators: 'header_indicators',
  header_decentralisation: 'header_decentralisation',
  header_useCases: 'header_useCases',
  header_Users: 'header_Users',
  header_Banks: 'header_Banks',
  header_Brokers: 'header_Brokers',
  header_Shopping: 'header_Shopping',
  header_Representatives: 'header_Representatives',
  header_cloudEconomy: 'header_cloudEconomy',
  header_Payments: 'header_Payments',
  header_Exchange: 'header_Exchange',
  header_Acquiring: 'header_Acquiring',
  header_Stablecoin: 'header_Stablecoin',
  header_Innovation: 'header_Innovation',
  header_Broker: 'header_Broker',
  header_Join: 'header_Join',
  header_integration: 'header_integration',
  header_bank: 'header_bank',
  header_broker: 'header_broker',
  header_store: 'header_store',
  header_api: 'header_api',
  header_about: 'header_about',
  header_Services: 'header_Services',
  header_Target: 'header_Target',
  header_Team: 'header_Team',
  header_Message: 'header_Message',
  header_Office: 'header_Office',
  header_myAccount: 'header_myAccount',
  header_logIn: 'header_logIn',
  header_Transfer: 'header_Transfer',
  header_Requests: 'header_Requests',
  header_Statement: 'header_Statement',
  header_Autoexchange: 'header_Autoexchange',
  faq_category: 'faq_category',
  faq_all: 'faq_all',
  faq_generalIssues: 'faq_generalIssues',
  faq_depositFunds: 'faq_depositFunds',
  faq_withdravalFunds: 'faq_withdravalFunds',
  faq_transferFunds: 'faq_transferFunds',
  faq_exchnageFunds: 'faq_exchnageFunds',
  faq_howRegistrer: 'faq_howRegistrer',
  faq_categoryGeneral: 'faq_categoryGeneral',
  faq_readMore: 'faq_readMore',
  faq_toRegister: 'faq_toRegister',
  faq_afterFolowing: 'faq_afterFolowing',
  faq_warning: 'faq_warning',
  faq_takeCare: 'faq_takeCare',
  faq_howLog: 'faq_howLog',
  faq_inOrder: 'faq_inOrder',
  faq_howEnterBitcoin: 'faq_howEnterBitcoin',
  faq_categoryDeposit: 'faq_categoryDeposit',
  faq_inOrderBitcoin: 'faq_inOrderBitcoin',
  faq_howEnterEth: 'faq_howEnterEth',
  faq_byClick: 'faq_byClick',
  faq_attention: 'faq_attention',
  faq_itImportant: 'faq_itImportant',
  faq_howWithdraval: 'faq_howWithdraval',
  faq_categoryWithdraval: 'faq_categoryWithdraval',
  faq_inOrderWithdr: 'faq_inOrderWithdr',
  faq_howTransfer: 'faq_howTransfer',
  faq_categoryTransfer: 'faq_categoryTransfer',
  faq_toTransfer: 'faq_toTransfer',
  faq_afterEntering: 'faq_afterEntering',
  faq_howExchange: 'faq_howExchange',
  faq_categoryExchange: 'faq_categoryExchange',
  faq_inOrderExchange: 'faq_inOrderExchange',
  faq_below: 'faq_below',
  faq_nextExchange: 'faq_nextExchange',
  faq_andAfter: 'faq_andAfter',
  faq_nextClick: 'faq_nextClick',
  faq_howCancelExchange: 'faq_howCancelExchange',
  faq_ifAnother: 'faq_ifAnother',
  faq_howAcceptExchange: 'faq_howAcceptExchange',
  faq_exchangeRequest: 'faq_exchangeRequest',
  faq_howRejectExchnage: 'faq_howRejectExchnage',
  faq_exhcangeRequest: 'faq_exhcangeRequest',
  ibmCloud_blockchain: 'ibmCloud_blockchain',
  ibmCloud_cloud: 'ibmCloud_cloud',
  ibmCloud_cloudText: 'ibmCloud_cloudText',
  ibmCloud_key: 'ibmCloud_key',
  ibmCloud_keyText: 'ibmCloud_keyText',
  ibmCloud_load: 'ibmCloud_load',
  ibmCloud_loadText: 'ibmCloud_loadText',
  ibmCloud_kub: 'ibmCloud_kub',
  ibmCloud_kubText: 'ibmCloud_kubText',
  ibmCloud_activ: 'ibmCloud_activ',
  ibmCloud_activText: 'ibmCloud_activText',
  video_header: 'video_header',
  video_functionality: 'video_functionality',
  tariffs_header: 'tariffs_header',
  tariffs_cryptoCurreci: 'tariffs_cryptoCurreci',
  tariffs_deposit: 'tariffs_deposit',
  tariffs_transfer: 'tariffs_transfer',
  tariffs_maxTransfer: 'tariffs_maxTransfer',
  tariffs_conversion: 'tariffs_conversion',
  tariffs_withdraval: 'tariffs_withdraval',
  tariffs_maxWithdraval: 'tariffs_maxWithdraval',
  tariffs_fiat: 'tariffs_fiat',
  tariffs_visa: 'tariffs_visa',
  tariffs_depositFiat: 'tariffs_depositFiat',
  tariffs_transferFiat: 'tariffs_transferFiat',
  tariffs_maxFiat: 'tariffs_maxFiat',
  tariffs_conversionFiat: 'tariffs_conversionFiat',
  tariffs_withdravalFiat: 'tariffs_withdravalFiat',
  tariffs_wireTransfer: 'tariffs_wireTransfer',
  tariffs_commissio: 'tariffs_commissio',
  tariffs_tariff: 'tariffs_tariff',
  tariffs_withdravalWire: 'tariffs_withdravalWire',
  tariffs_exchange: 'tariffs_exchange',
  tariffs_exchangeAnyFiat: 'tariffs_exchangeAnyFiat',
  tariffs_exchangeAnyCrypto: 'tariffs_exchangeAnyCrypto',
  tariffs_exchangeFiatFiat: 'tariffs_exchangeFiatFiat',
  tariffs_maxExchange: 'tariffs_maxExchange',
  tariffs_conversionExchange: 'tariffs_conversionExchange',
  payment_description: 'payment_description',
  payment_blockchainInfras: 'payment_blockchainInfras',
  payment_infraSolution: 'payment_infraSolution',
  payment_decentraFinan: 'payment_decentraFinan',
  payment_aggregate: 'payment_aggregate',
  payment_functional: 'payment_functional',
  payment_transfer: 'payment_transfer',
  payment_fiat: 'payment_fiat',
  payment_bayAndSell: 'payment_bayAndSell',
  payment_exchange: 'payment_exchange',
  payment_calculation: 'payment_calculation',
  payment_individ: 'payment_individ',
  payment_legal: 'payment_legal',
  payment_indivLegal: 'payment_indivLegal',
  payment_legalIndiv: 'payment_legalIndiv',
  payment_issueToken: 'payment_issueToken',
  payment_integra: 'payment_integra',
  payment_exchangeP2P: 'payment_exchangeP2P',
  payment_exchangeStore: 'payment_exchangeStore',
  payment_exchangeOperation: 'payment_exchangeOperation',
  payment_exchangeTraiding: 'payment_exchangeTraiding',
  payment_compare: 'payment_compare',
  payment_efficiency: 'payment_efficiency',
  payment_benefit: 'payment_benefit',
  payment_speed: 'payment_speed',
  payment_thro: 'payment_thro',
  payment_reliab: 'payment_reliab',
  payment_scalab: 'payment_scalab',
  payment_function: 'payment_function',
  payment_simpl: 'payment_simpl',
  payment_cost: 'payment_cost',
  payment_global: 'payment_global',
  payment_indicators: 'payment_indicators',
  payment_comingSoon: 'payment_comingSoon',
  payment_numberClients: 'payment_numberClients',
  payment_perSecond: 'payment_perSecond',
  payment_max: 'payment_max',
  payment_transCost: 'payment_transCost',
  payment_sec: 'payment_sec',
  payment_passingTransac: 'payment_passingTransac',
  payment_moneyOnline: 'payment_moneyOnline',
  payment_decentralization: 'payment_decentralization',
  payment_howDecentral: 'payment_howDecentral',
  payment_innovTech: 'payment_innovTech',
  payment_uniqSurpas: 'payment_uniqSurpas',
  payment_globalProject: 'payment_globalProject',
  payment_consediring: 'payment_consediring',
  payment_currMarcet: 'payment_currMarcet',
  payment_imageCompare: 'payment_imageCompare',
  payment_imageEfy: 'payment_imageEfy',
  useCases_users: 'useCases_users',
  useCases_toUsers: 'useCases_toUsers',
  useCases_basik: 'useCases_basik',
  useCases_transfer: 'useCases_transfer',
  useCases_instatnt: 'useCases_instatnt',
  useCases_instatntText: 'useCases_instatntText',
  useCases_currency: 'useCases_currency',
  useCases_currencyText: 'useCases_currencyText',
  useCases_wallet: 'useCases_wallet',
  useCases_walletText: 'useCases_walletText',
  useCases_cashPayment: 'useCases_cashPayment',
  useCases_cashPaymentText: 'useCases_cashPaymentText',
  useCases_through: 'useCases_through',
  useCases_throughText: 'useCases_throughText',
  useCases_via: 'useCases_via',
  useCases_viaText: 'useCases_viaText',
  useCases_exchange: 'useCases_exchange',
  useCases_blockchain: 'useCases_blockchain',
  useCases_instatntExchange: 'useCases_instatntExchange',
  useCases_instatntExchangeText: 'useCases_instatntExchangeText',
  useCases_impliment: 'useCases_impliment',
  useCases_exchangeSystem: 'useCases_exchangeSystem',
  useCases_exchangeDirectly: 'useCases_exchangeDirectly',
  useCases_theImplemented: 'useCases_theImplemented',
  useCases_exchangeTrading: 'useCases_exchangeTrading',
  useCases_exchangeTradingText: 'useCases_exchangeTradingText',
  useCases_goods: 'useCases_goods',
  useCases_goodsText: 'useCases_goodsText',
  useCases_currencyPairs: 'useCases_currencyPairs',
  useCases_currencyPairsText: 'useCases_currencyPairsText',
  useCases_cryptocurrecy: 'useCases_cryptocurrecy',
  useCases_cryptocurrecyText: 'useCases_cryptocurrecyText',
  useCases_globalLiquidity: 'useCases_globalLiquidity',
  useCases_globalLiquidityText: 'useCases_globalLiquidityText',
  useCases_bank: 'useCases_bank',
  useCases_forBanks: 'useCases_forBanks',
  useCases_invites: 'useCases_invites',
  useCases_gains: 'useCases_gains',
  useCases_newIncome: 'useCases_newIncome',
  useCases_cost: 'useCases_cost',
  useCases_accessGlabal: 'useCases_accessGlabal',
  useCases_accessMarket: 'useCases_accessMarket',
  useCases_expansion: 'useCases_expansion',
  useCases_functionality: 'useCases_functionality',
  useCases_functionalityText: 'useCases_functionalityText',
  useCases_acquiring: 'useCases_acquiring',
  useCases_acquiringText: 'useCases_acquiringText',
  useCases_onlineAquiring: 'useCases_onlineAquiring',
  useCases_onlineAquiringText: 'useCases_onlineAquiringText',
  useCases_retailAquiring: 'useCases_retailAquiring',
  useCases_retailAquiringText: 'useCases_retailAquiringText',
  useCases_pros: 'useCases_pros',
  useCases_cryptocurrencyAccept: 'useCases_cryptocurrencyAccept',
  useCases_cryptocurrencyAcceptText: 'useCases_cryptocurrencyAcceptText',
  useCases_connectAndMaining: 'useCases_connectAndMaining',
  useCases_connectAndMainingText: 'useCases_connectAndMainingText',
  useCases_costAquiring: 'useCases_costAquiring',
  useCases_costAquiringText: 'useCases_costAquiringText',
  useCases_payments: 'useCases_payments',
  useCases_paymentsText: 'useCases_paymentsText',
  useCases_exchangeOperation: 'useCases_exchangeOperation',
  useCases_exchangeOperationText: 'useCases_exchangeOperationText',
  useCases_p2p: 'useCases_p2p',
  useCases_p2pText: 'useCases_p2pText',
  useCases_comission: 'useCases_comission',
  useCases_comissionText: 'useCases_comissionText',
  useCases_stablecion: 'useCases_stablecion',
  useCases_stablecionText: 'useCases_stablecionText',
  useCases_hard: 'useCases_hard',
  useCases_hardText: 'useCases_hardText',
  useCases_virtualBank: 'useCases_virtualBank',
  useCases_usingStandart: 'useCases_usingStandart',
  useCases_byOpening: 'useCases_byOpening',
  useCases_openMulti: 'useCases_openMulti',
  useCases_create: 'useCases_create',
  useCases_carry: 'useCases_carry',
  useCases_openMultiAcconts: 'useCases_openMultiAcconts',
  useCases_carryOut: 'useCases_carryOut',
  useCases_issue: 'useCases_issue',
  useCases_following: 'useCases_following',
  useCases_virtualCrypto: 'useCases_virtualCrypto',
  useCases_virtualCryptoText: 'useCases_virtualCryptoText',
  useCases_allCrypto: 'useCases_allCrypto',
  useCases_virtualDigital: 'useCases_virtualDigital',
  useCases_virtualDigitalText: 'useCases_virtualDigitalText',
  useCases_allFiat: 'useCases_allFiat',
  useCases_brokers: 'useCases_brokers',
  useCases_affiliate: 'useCases_affiliate',
  useCases_digital: 'useCases_digital',
  useCases_ourPartners: 'useCases_ourPartners',
  useCases_lion: 'useCases_lion',
  useCases_receive: 'useCases_receive',
  useCases_sellLion: 'useCases_sellLion',
  useCases_shops: 'useCases_shops',
  useCases_toShops: 'useCases_toShops',
  useCases_invitesOwners: 'useCases_invitesOwners',
  useCases_youCan: 'useCases_youCan',
  useCases_withdrawing: 'useCases_withdrawing',
  useCases_Jpening: 'useCases_Jpening',
  useCases_currencyConversion: 'useCases_currencyConversion',
  useCases_accept: 'useCases_accept',
  useCases_whenYou: 'useCases_whenYou',
  useCases_retailAoutlet: 'useCases_retailAoutlet',
  useCases_gives: 'useCases_gives',
  useCases_having: 'useCases_having',
  useCases_savingCommission: 'useCases_savingCommission',
  useCases_savingCommissionText: 'useCases_savingCommissionText',
  useCases_cryptocurrency: 'useCases_cryptocurrency',
  useCases_cryptocurrencyText: 'useCases_cryptocurrencyText',
  useCases_additional: 'useCases_additional',
  useCases_additionalText: 'useCases_additionalText',
  useCases_lowCost: 'useCases_lowCost',
  useCases_lowCostText: 'useCases_lowCostText',
  useCases_workWith: 'useCases_workWith',
  useCases_theWork: 'useCases_theWork',
  useCases_issueFunds: 'useCases_issueFunds',
  useCases_providing: 'useCases_providing',
  useCases_commissions: 'useCases_commissions',
  useCases_cashThe: 'useCases_cashThe',
  useCases_cashTheText: 'useCases_cashTheText',
  useCases_installing: 'useCases_installing',
  useCases_instakkingText: 'useCases_instakkingText',
  useCases_financial: 'useCases_financial',
  useCases_financialStore: 'useCases_financialStore',
  useCases_financialPayment: 'useCases_financialPayment',
  useCases_accountOpening: 'useCases_accountOpening',
  useCases_accountOpeningText: 'useCases_accountOpeningText',
  useCases_cashService: 'useCases_cashService',
  useCases_cashServiceText: 'useCases_cashServiceText',
  useCases_repres: 'useCases_repres',
  useCases_represen: 'useCases_represen',
  useCases_beecom: 'useCases_beecom',
  useCases_regional: 'useCases_regional',
  useCases_result: 'useCases_result',
  useCases_customer: 'useCases_customer',
  useCases_youCanEarn: 'useCases_youCanEarn',
  useCases_connecting: 'useCases_connecting',
  useCases_banks: 'useCases_banks',
  useCases_other: 'useCases_other',
  useCases_selling: 'useCases_selling',
  useCases_exclusive: 'useCases_exclusive',
  useCases_exclusiveTextFirst: 'useCases_exclusiveTextFirst',
  useCases_exclusiveTextSecond: 'useCases_exclusiveTextSecond',
  useCases_token: 'useCases_token',
  useCases_tokenTextFirst: 'useCases_tokenTextFirst',
  useCases_tokenTextSecond: 'useCases_tokenTextSecond',
  index_safeOperation: 'index_safeOperation',
  index_HowWork: 'index_HowWork',
  index_slide1: 'index_slide1',
  index_slide2: 'index_slide2',
  index_slide3: 'index_slide3',
  index_slide4: 'index_slide4',
  index_slide5: 'index_slide5',
  index_slide6: 'index_slide6',
  index_slide7: 'index_slide7',
  index_serviceFeatures: 'index_serviceFeatures',
  index_payments: 'index_payments',
  index_instatntPayments: 'index_instatntPayments',
  index_messenger: 'index_messenger',
  index_messengerOperation: 'index_messengerOperation',
  index_IntegrationShops: 'index_IntegrationShops',
  index_connectServices: 'index_connectServices',
  index_londonStock: 'index_londonStock',
  index_exchangeTrade: 'index_exchangeTrade',
  index_bankOperations: 'index_bankOperations',
  index_operationUsers: 'index_operationUsers',
  index_currencyExchange: 'index_currencyExchange',
  index_conversionExchange: 'index_conversionExchange',
  index_digitalEra: 'index_digitalEra',
  index_digitalEraText: 'index_digitalEraText',
  index_digitalInfra: 'index_digitalInfra',
  index_digitalInfraText: 'index_digitalInfraText',
  index_ecosys: 'index_ecosys',
  index_economicRel: 'index_economicRel',
  index_economicRelText: 'index_economicRelText',
  index_ecosysText: 'index_ecosysText',
  index_levelTrust: 'index_levelTrust',
  index_levelTrustText: 'index_levelTrustText',
  index_digitalTransform: 'index_digitalTransform',
  index_users: 'index_users',
  index_usersText: 'index_usersText',
  index_busines: 'index_busines',
  index_businesText: 'index_businesText',
  index_banks: 'index_banks',
  index_banksText: 'index_banksText',
  index_exchange: 'index_exchange',
  index_exchangeText: 'index_exchangeText',
  index_forYou: 'index_forYou',
  index_forYouText: 'index_forYouText',
  index_developer: 'index_developer',
  index_technology: 'index_technology',
  index_joinUs: 'index_joinUs',
  index_enterNewEra: 'index_enterNewEra',
  index_joinEra: 'index_joinEra',
  index_schemeHome: 'index_schemeHome',
  about_header: 'about_header',
  about_distributed: 'about_distributed',
  about_infrastructure: 'about_infrastructure',
  about_decentral: 'about_decentral',
  about_agregate: 'about_agregate',
  about_ourGoals: 'about_ourGoals',
  about_novem: 'about_novem',
  about_platform: 'about_platform',
  about_jul: 'about_jul',
  about_launch: 'about_launch',
  about_feb: 'about_feb',
  about_expansion: 'about_expansion',
  about_dec: 'about_dec',
  about_starting: 'about_starting',
  about_nov: 'about_nov',
  about_exchange: 'about_exchange',
  about_sep: 'about_sep',
  about_startProject: 'about_startProject',
  about_team: 'about_team',
  about_smartBlock: 'about_smartBlock',
  about_purpose: 'about_purpose',
  about_purposeText: 'about_purposeText',
  about_tasks: 'about_tasks',
  about_tasksText: 'about_tasksText',
  about_active: 'about_active',
  about_ended: 'about_ended',
  about_andrey: 'about_andrey',
  about_headCIS: 'about_headCIS',
  about_answering: 'about_answering',
  about_holds: 'about_holds',
  about_sergey: 'about_sergey',
  about_evgeniy: 'about_evgeniy',
  about_damir: 'about_damir',
  about_artem: 'about_artem',
  about_ali: 'about_ali',
  about_alexsandr: 'about_alexsandr',
  about_yulia: 'about_yulia',
  about_yurijMironov: 'about_yurijMironov',
  about_yurijKr: 'about_yurijKr',
  about_greg: 'about_greg',
  about_vadim: 'about_vadim',
  about_message: 'about_message',
  about_emergency: 'about_emergency',
  about_since: 'about_since',
  about_decentralisation: 'about_decentralisation',
  about_connect: 'about_connect',
  about_join: 'about_join',
  about_blockchain: 'about_blockchain',
  about_network: 'about_network',
  about_conclusion: 'about_conclusion',
  about_security: 'about_security',
  about_implementation: 'about_implementation',
  about_digital: 'about_digital',
  about_provides: 'about_provides',
  about_ceo: 'about_ceo',
  about_office: 'about_office',
  about_head: 'about_head',
  about_name: 'about_name',
  about_corpName: 'about_corpName',
  about_registry: 'about_registry',
  about_address1: 'about_address1',
  about_address2: 'about_address2',
  about_license: 'about_license',
  about_walletService: 'about_walletService',
  about_number: 'about_number',
  about_start: 'about_start',
  about_virtualCur: 'about_virtualCur',
  about_may: 'about_may',
  airDrop_header: 'airDrop_header',
  airDrop_dear: 'airDrop_dear',
  airDrop_launched: 'airDrop_launched',
  airDrop_time: 'airDrop_time',
  airDrop_join: 'airDrop_join',
  airDrop_for: 'airDrop_for',
  airDrop_users: 'airDrop_users',
  airDrop_tokens: 'airDrop_tokens',
  airDrop_tokensBe: 'airDrop_tokensBe',
  airDrop_first: 'airDrop_first',
  airDrop_participantsFull: 'airDrop_participantsFull',
  airDrop_amongtFirst: 'airDrop_amongtFirst',
  airDrop_participants: 'airDrop_participants',
  airDrop_prize: 'airDrop_prize',
  airDrop_now: 'airDrop_now',
  airDrop_basik: 'airDrop_basik',
  airDrop_mainTask: 'airDrop_mainTask',
  airDrop_registr: 'airDrop_registr',
  airDrop_take: 'airDrop_take',
  airDrop_folow: 'airDrop_folow',
  airDrop_teleGroup: 'airDrop_teleGroup',
  airDrop_teleChan: 'airDrop_teleChan',
  airDrop_social: 'airDrop_social',
  airDrop_subscribe: 'airDrop_subscribe',
  airDrop_optional: 'airDrop_optional',
  airDrop_additionalTask: 'airDrop_additionalTask',
  airDrop_like: 'airDrop_like',
  airDrop_likeRetweet: 'airDrop_likeRetweet',
  airDrop_likeRepost: 'airDrop_likeRepost',
  airDrop_put: 'airDrop_put',
  airDrop_lik: 'airDrop_lik',
  airDrop_invite: 'airDrop_invite',
  airDrop_report: 'airDrop_report',
  airDrop_complete: 'airDrop_complete',
  airDrop_please: 'airDrop_please',
  airDrop_emailNoty: 'airDrop_emailNoty',
  airDrop_payment: 'airDrop_payment',
  airDrop_completeTask: 'airDrop_completeTask',
  airDrop_programValid: 'airDrop_programValid',
  documents_header: 'documents_header',
  documents_aml: 'documents_aml',
  documents_termUse: 'documents_termUse',
  documents_termService: 'documents_termService',
  tokenSale_round1_name: 'tokenSale_round1_name',
  tokenSale_round1_pricePerEth: 'tokenSale_round1_pricePerEth',
  tokenSale_round1_distributed: 'tokenSale_round1_distributed',
  tokenSale_round1_tokenPrice_ETH: 'tokenSale_round1_tokenPrice_ETH',
  tokenSale_round1_tokenPrice_BTC: 'tokenSale_round1_tokenPrice_BTC',
  tokenSale_round1_tokenPrice_LTC: 'tokenSale_round1_tokenPrice_LTC',
  tokenSale_round1_minimalPurchase_ETH: 'tokenSale_round1_minimalPurchase_ETH',
  tokenSale_round1_minimalPurchase_BTC: 'tokenSale_round1_minimalPurchase_BTC',
  tokenSale_round1_minimalPurchase_LTC: 'tokenSale_round1_minimalPurchase_LTC',
  tokenSale_round1_endTime: 'tokenSale_round1_endTime',
  tokenSale_round1_startTime: 'tokenSale_round1_startTime',
  tokenSale_round2_name: 'tokenSale_round2_name',
  tokenSale_round2_pricePerEth: 'tokenSale_round2_pricePerEth',
  tokenSale_round2_distributed: 'tokenSale_round2_distributed',
  tokenSale_round2_tokenPrice_ETH: 'tokenSale_round2_tokenPrice_ETH',
  tokenSale_round2_tokenPrice_BTC: 'tokenSale_round2_tokenPrice_BTC',
  tokenSale_round2_tokenPrice_LTC: 'tokenSale_round2_tokenPrice_LTC',
  tokenSale_round2_minimalPurchase_ETH: 'tokenSale_round2_minimalPurchase_ETH',
  tokenSale_round2_minimalPurchase_BTC: 'tokenSale_round2_minimalPurchase_BTC',
  tokenSale_round2_minimalPurchase_LTC: 'tokenSale_round2_minimalPurchase_LTC',
  tokenSale_round2_endTime: 'tokenSale_round2_endTime',
  tokenSale_round2_startTime: 'tokenSale_round2_startTime',
  tokenSale_round3_name: 'tokenSale_round3_name',
  tokenSale_round3_pricePerEth: 'tokenSale_round3_pricePerEth',
  tokenSale_round3_distributed: 'tokenSale_round3_distributed',
  tokenSale_round3_tokenPrice_ETH: 'tokenSale_round3_tokenPrice_ETH',
  tokenSale_round3_tokenPrice_BTC: 'tokenSale_round3_tokenPrice_BTC',
  tokenSale_round3_tokenPrice_LTC: 'tokenSale_round3_tokenPrice_LTC',
  tokenSale_round3_minimalPurchase_ETH: 'tokenSale_round3_minimalPurchase_ETH',
  tokenSale_round3_minimalPurchase_BTC: 'tokenSale_round3_minimalPurchase_BTC',
  tokenSale_round3_minimalPurchase_LTC: 'tokenSale_round3_minimalPurchase_LTC',
  tokenSale_round3_endTime: 'tokenSale_round3_endTime',
  tokenSale_round3_startTime: 'tokenSale_round3_startTime',
  tokenSale_round4_name: 'tokenSale_round4_name',
  tokenSale_round4_pricePerEth: 'tokenSale_round4_pricePerEth',
  tokenSale_round4_distributed: 'tokenSale_round4_distributed',
  tokenSale_round4_tokenPrice_ETH: 'tokenSale_round4_tokenPrice_ETH',
  tokenSale_round4_tokenPrice_BTC: 'tokenSale_round4_tokenPrice_BTC',
  tokenSale_round4_tokenPrice_LTC: 'tokenSale_round4_tokenPrice_LTC',
  tokenSale_round4_minimalPurchase_ETH: 'tokenSale_round4_minimalPurchase_ETH',
  tokenSale_round4_minimalPurchase_BTC: 'tokenSale_round4_minimalPurchase_BTC',
  tokenSale_round4_minimalPurchase_LTC: 'tokenSale_round4_minimalPurchase_LTC',
  tokenSale_round4_endTime: 'tokenSale_round4_endTime',
  tokenSale_round4_startTime: 'tokenSale_round4_startTime',
  tokenSale_banner_default_title: 'tokenSale_banner_default_title',
  tokenSale_banner_default_subtitle: 'tokenSale_banner_default_subtitle',
  tokenSale_banner_community_title: 'tokenSale_banner_community_title',
  tokenSale_banner_community_subtitle: 'tokenSale_banner_community_subtitle',
  tokenSale_banner_community_getBonus: 'tokenSale_banner_community_getBonus',
  tokenSale_banner_referral_title: 'tokenSale_banner_referral_title',
  tokenSale_banner_referral_subtitle: 'tokenSale_banner_referral_subtitle',
  tokenSale_banner_referral_getBonus: 'tokenSale_banner_referral_getBonus',
  tokenSale_banner_personal_title: 'tokenSale_banner_personal_title',
  tokenSale_banner_personal_subtitle: 'tokenSale_banner_personal_subtitle',
  tokenSale_banner_personal_getBonus: 'tokenSale_banner_personal_getBonus',
  tokenSale_page_title: 'tokenSale_page_title',
  tokenSale_page_subtitle: 'tokenSale_page_subtitle',
  tokenSale_page_purchase: 'tokenSale_page_purchase',
  tokenSale_page_note1: 'tokenSale_page_note1',
  tokenSale_page_note2: 'tokenSale_page_note2',
  tokenSale_page_completionOf1: 'tokenSale_page_completionOf1',
  tokenSale_page_completionOf2: 'tokenSale_page_completionOf2',
  tokenSale_page_tokenSpecification_title: 'tokenSale_page_tokenSpecification_title',
  tokenSale_page_tokenSpecification_name: 'tokenSale_page_tokenSpecification_name',
  tokenSale_page_tokenSpecification_ticker: 'tokenSale_page_tokenSpecification_ticker',
  tokenSale_page_tokenSpecification_supply: 'tokenSale_page_tokenSpecification_supply',
  tokenSale_page_tokenSpecification_available1: 'tokenSale_page_tokenSpecification_available1',
  tokenSale_page_tokenSpecification_available2: 'tokenSale_page_tokenSpecification_available2',
  tokenSale_page_tokenSpecification_cap: 'tokenSale_page_tokenSpecification_cap',
  tokenSale_page_tokenSpecification_minimumDepo: 'tokenSale_page_tokenSpecification_minimumDepo',
  tokenSale_page_tokenSpecification_liquidity: 'tokenSale_page_tokenSpecification_liquidity',
  tokenSale_page_tokenSpecification_listingPrice: 'tokenSale_page_tokenSpecification_listingPrice',
  tokenSale_page_tokenSpecification_listingDate1: 'tokenSale_page_tokenSpecification_listingDate1',
  tokenSale_page_tokenSpecification_listingDate2: 'tokenSale_page_tokenSpecification_listingDate2',
  tokenSale_page_tokenSpecification_overPresale: 'tokenSale_page_tokenSpecification_overPresale',
  tokenSale_page_publicationTitle: 'tokenSale_page_publicationTitle',
  tokenSale_page_developed: 'tokenSale_page_developed',
  tokenSale_page_howTo: 'tokenSale_page_howTo',
  tokenSale_page_option1: 'tokenSale_page_option1',
  tokenSale_page_addressToPurchase: 'tokenSale_page_addressToPurchase',
  tokenSale_page_toReceive: 'tokenSale_page_toReceive',
  tokenSale_page_getBonus: 'tokenSale_page_getBonus',
  tokenSale_page_delivery: 'tokenSale_page_delivery',
  tokenSale_page_disturbed: 'tokenSale_page_disturbed',
  tokenSale_page_reminder: 'tokenSale_page_reminder',
  tokenSale_page_sendOnly: 'tokenSale_page_sendOnly',
  tokenSale_page_doNotSend: 'tokenSale_page_doNotSend',
  tokenSale_page_option2: 'tokenSale_page_option2',
  tokenSale_page_registration: 'tokenSale_page_registration',
  tokenSale_page_deposit: 'tokenSale_page_deposit',
  tokenSale_page_makeTransfer: 'tokenSale_page_makeTransfer',
  tokenSale_page_getAdditional: 'tokenSale_page_getAdditional',
  tokenSale_page_whenChoosing: 'tokenSale_page_whenChoosing',
  tokenSale_bt_step1: 'tokenSale_bt_step1',
  tokenSale_bt_step2: 'tokenSale_bt_step2',
  tokenSale_bt_step3: 'tokenSale_bt_step3',
  tokenSale_bt_buy: 'tokenSale_bt_buy',
  tokenSale_bt_signUpOrLogIn: 'tokenSale_bt_signUpOrLogIn',
  tokenSale_buyWith: 'tokenSale_buyWith',
  footer_aboutCompany: 'footer_aboutCompany',
  footer_aboutService: 'footer_aboutService',
  footer_service: 'footer_service',
  footer_contactUs: 'footer_contactUs',
  footer_team: 'footer_team',
  footer_support: 'footer_support',
  footer_api: 'footer_api',
  footer_faq: 'footer_faq',
  footer_video: 'footer_video',
  footer_resourse: 'footer_resourse',
  footer_tariffs: 'footer_tariffs',
  footer_news: 'footer_news',
  footer_ibmCloud: 'footer_ibmCloud',
  footer_airDrop: 'footer_airDrop',
  footer_lionErc: 'footer_lionErc',
  footer_drawing: 'footer_drawing',
  footer_legal: 'footer_legal',
  footer_smartBlock: 'footer_smartBlock',
  footer_documents: 'footer_documents',
  footer_subscribe: 'footer_subscribe',
  footer_right: 'footer_right',
  footer_whitepaper: 'footer_whitepaper',
  footer_enter: 'footer_enter',
  lionErc_header: 'lionErc_header',
  lionErc_details: 'lionErc_details',
  lionErc_description: 'lionErc_description',
  lionErc_simbol: 'lionErc_simbol',
  lionErc_address: 'lionErc_address',
  lionErc_liquid: 'lionErc_liquid',
  lionErc_integration: 'lionErc_integration',
  cloudEconomy_payments: 'cloudEconomy_payments',
  cloudEconomy_howPayments: 'cloudEconomy_howPayments',
  cloudEconomy_globalCross: 'cloudEconomy_globalCross',
  cloudEconomy_speed: 'cloudEconomy_speed',
  cloudEconomy_speedText: 'cloudEconomy_speedText',
  cloudEconomy_cost: 'cloudEconomy_cost',
  cloudEconomy_costText: 'cloudEconomy_costText',
  cloudEconomy_global: 'cloudEconomy_global',
  cloudEconomy_globalText: 'cloudEconomy_globalText',
  cloudEconomy_exchangeValues: 'cloudEconomy_exchangeValues',
  cloudEconomy_provides: 'cloudEconomy_provides',
  cloudEconomy_exchangeUsers: 'cloudEconomy_exchangeUsers',
  cloudEconomy_exchangeUsersText: 'cloudEconomy_exchangeUsersText',
  cloudEconomy_exchangeTraiding: 'cloudEconomy_exchangeTraiding',
  cloudEconomy_exchangeTraidingText: 'cloudEconomy_exchangeTraidingText',
  cloudEconomy_exchangeOperation: 'cloudEconomy_exchangeOperation',
  cloudEconomy_exchangeOperationText: 'cloudEconomy_exchangeOperationText',
  cloudEconomy_acquiring: 'cloudEconomy_acquiring',
  cloudEconomy_acquiringText: 'cloudEconomy_acquiringText',
  cloudEconomy_aquExchangeUsers: 'cloudEconomy_aquExchangeUsers',
  cloudEconomy_aquExchangeUsersText: 'cloudEconomy_aquExchangeUsersText',
  cloudEconomy_aquExchnageTrading: 'cloudEconomy_aquExchnageTrading',
  cloudEconomy_aquExchnageTradingText: 'cloudEconomy_aquExchnageTradingText',
  cloudEconomy_aquExchnageOperation: 'cloudEconomy_aquExchnageOperation',
  cloudEconomy_aquExchnageOperationText: 'cloudEconomy_aquExchnageOperationText',
  cloudEconomy_p2pLanding: 'cloudEconomy_p2pLanding',
  cloudEconomy_p2pLandingText: 'cloudEconomy_p2pLandingText',
  cloudEconomy_withYouCan: 'cloudEconomy_withYouCan',
  cloudEconomy_issue: 'cloudEconomy_issue',
  cloudEconomy_getSecureted: 'cloudEconomy_getSecureted',
  cloudEconomy_payLowest: 'cloudEconomy_payLowest',
  cloudEconomy_interest: 'cloudEconomy_interest',
  cloudEconomy_crossBorder: 'cloudEconomy_crossBorder',
  cloudEconomy_stablecoinRelease: 'cloudEconomy_stablecoinRelease',
  cloudEconomy_framework: 'cloudEconomy_framework',
  cloudEconomy_usersIssue: 'cloudEconomy_usersIssue',
  cloudEconomy_mechanism: 'cloudEconomy_mechanism',
  cloudEconomy_uniqueMehanism: 'cloudEconomy_uniqueMehanism',
  cloudEconomy_tokenAccount: 'cloudEconomy_tokenAccount',
  cloudEconomy_tocenIssue: 'cloudEconomy_tocenIssue',
  cloudEconomy_davidentPayment: 'cloudEconomy_davidentPayment',
  cloudEconomy_targetCost: 'cloudEconomy_targetCost',
  cloudEconomy_innovation: 'cloudEconomy_innovation',
  cloudEconomy_innovationText: 'cloudEconomy_innovationText',
  cloudEconomy_flexible: 'cloudEconomy_flexible',
  cloudEconomy_retailPoints: 'cloudEconomy_retailPoints',
  cloudEconomy_availa: 'cloudEconomy_availa',
  cloudEconomy_availaText: 'cloudEconomy_availaText',
  cloudEconomy_scala: 'cloudEconomy_scala',
  cloudEconomy_scalaText: 'cloudEconomy_scalaText',
  cloudEconomy_innova: 'cloudEconomy_innova',
  cloudEconomy_innovaText: 'cloudEconomy_innovaText',
  cloudEconomy_brokerage: 'cloudEconomy_brokerage',
  cloudEconomy_brokerageText: 'cloudEconomy_brokerageText',
  cloudEconomy_using: 'cloudEconomy_using',
  cloudEconomy_openAccount: 'cloudEconomy_openAccount',
  cloudEconomy_tradeAssets: 'cloudEconomy_tradeAssets',
  cloudEconomy_engine: 'cloudEconomy_engine',
  cloudEconomy_engineText: 'cloudEconomy_engineText',
  cloudEconomy_portal: 'cloudEconomy_portal',
  cloudEconomy_portalText: 'cloudEconomy_portalText',
  cloudEconomy_market: 'cloudEconomy_market',
  cloudEconomy_marketText: 'cloudEconomy_marketText',
  cloudEconomy_blockchain: 'cloudEconomy_blockchain',
  cloudEconomy_usersIndivid: 'cloudEconomy_usersIndivid',
  cloudEconomy_retail: 'cloudEconomy_retail',
  cloudEconomy_banks: 'cloudEconomy_banks',
  statement_statement: 'statement_statement',
  statement_currency: 'statement_currency',
  statement_userId: 'statement_userId',
  statement_dateFrom: 'statement_dateFrom',
  statement_dateTo: 'statement_dateTo',
  statement_myWallet: 'statement_myWallet',
  statement_txs: 'statement_txs',
  statement_date: 'statement_date',
  statement_user: 'statement_user',
  statement_amount: 'statement_amount',
  statement_sellOrBuy: 'statement_sellOrBuy',
  statement_depositFromMN: 'statement_depositFromMN',
  statement_withdrawalToMN: 'statement_withdrawalToMN',
  statement_depositFromTN: 'statement_depositFromTN',
  statement_withdrawalToTN: 'statement_withdrawalToTN',
  statement_txAddress: 'statement_txAddress',
  statement_txHash: 'statement_txHash',
  statement_blockTime: 'statement_blockTime',
  statement_from: 'statement_from',
  statement_to: 'statement_to',
  statement_inclusion: 'statement_inclusion',
  statement_txInProg: 'statement_txInProg',
  statement_netFee: 'statement_netFee',
  sysUser_sysComm: 'sysUser_sysComm',
  sysUser_lockComm: 'sysUser_lockComm',
  sysUser_withdrawal: 'sysUser_withdrawal',
  sysUser_deposit: 'sysUser_deposit',
  sysUser_lock: 'sysUser_lock',
  referral_refProgramTitle: 'referral_refProgramTitle',
  referral_chooseInteresting: 'referral_chooseInteresting',
  referral_progs_cryptoenter: 'referral_progs_cryptoenter',
  referral_progs_tokenSale: 'referral_progs_tokenSale',
  referral_progs_passiveIncome: 'referral_progs_passiveIncome',
  referral_both_forParticipating: 'referral_both_forParticipating',
  referral_both_buyLion: 'referral_both_buyLion',
  referral_both_getLink: 'referral_both_getLink',
  referral_both_inviteFrends: 'referral_both_inviteFrends',
  referral_pi_title1: 'referral_pi_title1',
  referral_pi_title2: 'referral_pi_title2',
  referral_pi_text: 'referral_pi_text',
  referral_pi_title3: 'referral_pi_title3',
  referral_pi_income: 'referral_pi_income',
  referral_pi_cashback: 'referral_pi_cashback',
  referral_ts_title1: 'referral_ts_title1',
  referral_ts_text: 'referral_ts_text',
  referral_ts_1: 'referral_ts_1',
  referral_ts_2: 'referral_ts_2',
  referral_ts_3: 'referral_ts_3',
  referral_ts_4: 'referral_ts_4',
  referral_ts_5: 'referral_ts_5',
  referral_letter_title: 'referral_letter_title',
  referral_letter_text: 'referral_letter_text',
  referral_letter_link: 'referral_letter_link',
  referral_letter_t0: 'referral_letter_t0',
  referral_letter_t1: 'referral_letter_t1',
  referral_letter_ibmLink: 'referral_letter_ibmLink',
  referral_letter_t2: 'referral_letter_t2',
  referral_msg_title: 'referral_msg_title',
  referral_msg_text: 'referral_msg_text',
  referral_msg_link: 'referral_msg_link',
  referral_msg_t1: 'referral_msg_t1',
  referral_msg_ibmLink: 'referral_msg_ibmLink',
  referral_msg_t2: 'referral_msg_t2',
  ae_autoexchange: 'ae_autoexchange',
  ae_title: 'ae_title',
  ae_buy: 'ae_buy',
  ae_sell: 'ae_sell',
  ae_request: 'ae_request',
  ae_rate: 'ae_rate',
  ae_orderCost: 'ae_orderCost',
  ae_willPay: 'ae_willPay',
  ae_willGet: 'ae_willGet',
  ae_willGetMarketRate: 'ae_willGetMarketRate',
  ae_commission: 'ae_commission',
  ae_contBuy: 'ae_contBuy',
  ae_contSell: 'ae_contSell',
  ae_matches: 'ae_matches',
  ae_currRequests: 'ae_currRequests',
  ae_nothingHereYet: 'ae_nothingHereYet',
  ae_marketRate: 'ae_marketRate',
  ae_youBuying: 'ae_youBuying',
  ae_youSelling: 'ae_youSelling',
  ae_youBought: 'ae_youBought',
  ae_youSold: 'ae_youSold',
  ae_dig_supply: 'ae_dig_supply',
  ae_dig_demand: 'ae_dig_demand',
  ae_dig_buying: 'ae_dig_buying',
  ae_dig_selling: 'ae_dig_selling',
  ae_dig_for: 'ae_dig_for',
  ae_dig_any1: 'ae_dig_any1',
  ae_dig_any2: 'ae_dig_any2',
  ae_dig_title: 'ae_dig_title',
  common_confirm: 'common_confirm',
  common_ok: 'common_ok',
  common_buyLion: 'common_buyLion',
  common_enterAddress: 'common_enterAddress',
  common_enterAmount: 'common_enterAmount',
  common_ALL: 'common_ALL',
  common_rate: 'common_rate',
  common_timeoutMsg: 'common_timeoutMsg',
  common_timeoutExchangeMsg: 'common_timeoutExchangeMsg',
  buyLions_title: 'buyLions_title',
  buyLions_enterAmount: 'buyLions_enterAmount',
  buyLions_rate: 'buyLions_rate',
  buyLions_currency: 'buyLions_currency',
  buyLions_youPay: 'buyLions_youPay',
  buyLions_buy: 'buyLions_buy',
  withdrawal_withdrawal: 'withdrawal_withdrawal',
  withdrawal_title: 'withdrawal_title',
  withdrawal_selectCurr: 'withdrawal_selectCurr',
  withdrawal_amount: 'withdrawal_amount',
  withdrawal_wallet: 'withdrawal_wallet'
}
